<template>
    <div class="page-asap-configuration">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Configuration</h1>

                    <app-tabs v-model="tab" :tabs="tabs"></app-tabs>

                    <router-view @open-sidebar="openSidebar" @close-sidebar="closeSidebar"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appTabs from '@/components/app-tabs'

export default {
    components: {
        appTabs,
    },

    methods: {
        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.$emit('close-sidebar')
        },
    },

    computed: {
        ...mapGetters([
            'vfenvironment_options',
        ]),

        tabs() {
            const tabs = []

            for (const key in this.vfenvironment_options) {
                tabs.push({
                    id: key,
                    name: 'asap-configuration-list',
                    title: this.vfenvironment_options[key],
                    params: {
                        env: key,
                    },
                })
            }

            return tabs
        },

        tab: {
            get() {
                const tab = this.tabs.find(tab => this.$route.params.env == tab.params.env)

                return tab
            },

            set(tab) {
                this.$router.push({ name: tab.name, params: { env: tab.params.env }, replace: true })
            },
        },
    },
}
</script>

<style lang="scss">
.page-asap-configuration {
    h1 {
        &.heading {
            margin-bottom: 40px;
        }
    }

    .app-tabs {
        margin-bottom: 34px;
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .page-asap-configuration {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .app-tabs {
            margin-bottom: 16px;
        }
    }
}
</style>